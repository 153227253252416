.contact-us-section{
  background-color: #F5F5F5;
  padding: 100px 0;
  position: relative;
  margin-bottom: 20px;

  .form-wrapper{
    width: 50%;
  }
  .white-half-smiles{
    width: 10%;
    position: absolute;
    left: 0;
    bottom: 15%;
  }

  .contact-us-btn{
    margin-top: 10px;
    color: white;
    background-color: #00AD00;
    font-size: 18px;
    padding: 5px 30px;
    border: none;
    border-radius: 0;
    transition: 0.2s;

    &:hover{
      background-color: rgb(2, 155, 2)
    }
  }


  @media (max-width: 768px){
    .white-half-smiles{
      width: 15%;
      position: absolute;
      left: 0;
      bottom: 8%;
    }
  }
}