.input-field{
    border: 1px solid transparent !important;
    border-radius: 0 !important;
    height: 48px;

    &:focus {
        border: 1px solid #FE8901 !important;
        outline: none !important;
        box-shadow: none !important;
    }
}