@import "variables";
.home-wrapper {
  position: relative;
  min-height: 100vh;
  background-image: linear-gradient(
      to bottom,
      rgba(8, 2, 7, 0.8),
      rgba(8, 2, 7, 0.4)
    ),
    url("../images/landing-bg-mob.png");
  background-size: cover;
  background-position: center center;
  padding-top: 100px;

  .home-header {
    height: calc(100vh - 100px);
  }
  h1 {
    font-size: 2rem;
    color: white;
  }
  .home-header-btn {
    background-color: $secondary;
    color: white;
    padding: 5px 15px;
    border-color: transparent;
    border-radius: 8px;
    font-size: 20px;
    margin-top: 30px;
    box-shadow: 0px 3px 6px #00000029;

    &:hover {
      box-shadow: 0px 3px 6px #00000010;
    }
  }
}

@media (min-width: 600px) {
  .home-wrapper {
    background-image: linear-gradient(
        to bottom,
        rgba(8, 2, 7, 0.3),
        rgba(8, 2, 7, 0.4)
      ),
      url("../images/landing-bg-hi.png");
  }
}
.search-container {
  padding: 30px;
  min-height: 180px;
  background-color: white;
  color: $primary;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 1100px;
  z-index: 1;
  border: 1px solid lightgray;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;

  h3 {
    min-width: 180px;
  }
  .home-select {
    width: 100%;
    min-width: 100px;
    max-width: 180px;
    box-shadow: 0px 3px 6px #00000029;
    color: $light-gray;
  }
  .select__indicators,
  .select__indicator-separator,
  .select__indicator {
    display: none !important;
  }
  .select__single-value {
    color: $light-gray;
  }
  .home-search-btn {
    background-color: $primary;
    color: white;
    padding: 5px 30px;
    border-color: transparent;
    border-radius: 8px;
    font-size: 20px;
    box-shadow: 0px 3px 6px #00000029;

    &:hover {
      box-shadow: 0px 3px 6px #00000010;
    }
  }
}

@media (min-width: 768px) {
  .search-boxes {
    flex: 3;
  }
  .search-select {
    width: 200px;
  }
}

.courses-list {
  h2 {
    margin-top: 250px;
    font-size: 2.5rem;
  }
  @media (min-width: 769px) {
    h2 {
      margin-top: 150px;
    }
  }
  @media (min-width: 730px) {
    .home-container {
      max-width: 730px !important;
    }
    .course-cards {
      justify-content: end !important;
    }
  }
  @media (min-width: 1094px) {
    .home-container {
      max-width: 1094px !important;
    }
  }

  .course-card {
    height: inherit;
    width: 340px;
    box-shadow: 0px 3px 6px #00000029;

    .course-card-img {
      height: 250px;
      overflow: hidden;

      img {
        width: 340px;
        height: 250px;
        object-fit: cover;
      }
    }
    .course-card-content {
      border-bottom: 1px solid $secondary;
      border-left: 1px solid $secondary;
      border-right: 1px solid $secondary;
      height: 200px;
      padding: 20px 20px 10px 20px;

      .card-label {
        font-size: 0.8rem;
      }
      .card-label-icon {
        width: 16px;
        color: $light-gray;
      }
      .card-light-text {
        color: $light-gray;
        font-size: 18px;
      }
      .home-card-btn {
        padding: 3px 10px;

        &:hover {
          box-shadow: 0px 3px 6px #00000010;
        }
      }

      // .home-card-btn {
      //   position: relative;
      //   border: none;
      //   box-shadow: 0px 3px 6px #00000029;
      //   color: white;
      //   z-index: 1;
      //   display: flex;
      //   align-items: center;
      //   justify-content: flex-end;
      //   padding-right: 3px 10px;
      //   font-size: 12px;
      //   margin-left: 20px;
      // }

      // .home-card-btn:before {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      //   width: 70px;
      //   height: 100%; /* Adjust the height to 100% */
      //   background-color: $primary;
      //   transform: skew(340deg);
      //   z-index: -1;
      // }

      // .home-card-btn:after {
      //   content: "";
      //   position: absolute;
      //   top: 1px;
      //   right: 75px;
      //   width: 6px;
      //   height: 90%;
      //   background-color: $primary;
      //   transform: skewY(340deg) rotate(25deg);
      // }


      .regular-btn {
        border: none;
        box-shadow: 0px 3px 6px #00000029;
        color: white;
        z-index: 1;
        padding-right: 3px 10px;
        font-size: 12px;
        margin-left: 20px;
        background-color: $primary;
        padding: 5px 20px;
        margin-bottom: 10px;
      }
    }
  }
}
