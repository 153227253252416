@import "variables";

.trainings {
  background-color: #f5f5f5;
  padding: 50px 0;
  cursor: pointer;
  
  .trainings-container {
    font-weight: bold;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    overflow: hidden;
    align-items: stretch !important;
    cursor: pointer;

    .training-wrapper {
      overflow: hidden;
      position: relative;
      width: 100%;
      transform-origin: right;
      transition: transform 0.4s, width 0.4s;
      min-height: 70vh;

      &.hover-to-right {
        width: 500%;
        z-index: 1000;
        .heading {
          font-size: 22px;
        }
      }

      &.hover-to-left {
        transform: translateX(-50%);
        width: 500%;
        z-index: 1000;
        .heading {
          font-size: 22px;
        }
      }

      &.slide4-wrapper {
        transform: translateX(-20%);
      }
      &.slide3-wrapper {
        transform: translateX(-40%);
      }
      &.slide2-wrapper {
        transform: translateX(-60%);
      }
      &.slide1-wrapper {
        transform: translateX(-80%);
      }

      @media only screen and (min-width: 768px) {
        &.slide5-wrapper {
          width: 500%;
        }
        &.slide4-wrapper {
          transform: translateX(-20%);
          width: 500%;
        }
        &.slide3-wrapper {
          transform: translateX(-40%);
          width: 500%;
        }
        &.slide2-wrapper {
          transform: translateX(-60%);
          width: 500%;
        }
        &.slide1-wrapper {
          transform: translateX(-80%);
          width: 500%;
        }
        &:hover {
          .detail-1,
          .detail-2,
          .detail-3,
          .detail-4,
          .detail-5 {
            min-height: 70vh;

            &::-webkit-scrollbar {
              width: 6px;
            }

            &::-webkit-scrollbar-track {
              background: #ffffff;
              border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #c9c9c9;
              border-radius: 10px;
            }
          }
        }
      }
      @media only screen and (min-width: 1100px) {
        &.slide5-wrapper {
          width: 500%;
        }
        &.slide4-wrapper {
          transform: translateX(-20%);
          width: 500%;
        }
        &.slide3-wrapper {
          transform: translateX(-40%);
          width: 500%;
        }
        &.slide2-wrapper {
          transform: translateX(-60%);
          width: 500%;
        }
        &.slide1-wrapper {
          transform: translateX(-80%);
          width: 500%;
        }
      }
    }

    .training {
      // position: absolute;
      width: 100%;
      cursor: pointer;

      .heading {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
        gap: 20px;
        padding: 5px;
        color: white !important;
        font-size: 14px;
        position: relative;
        min-height: 160px;
      }
      .heading p {
        min-height: 60px;
      }

      .detail-1 {
        background-color: $orange-2;
        border-left: 20px solid #f5c368;
        min-height: 70vh;
        
        &.open-1{
          background-image: url('../images/trainings/new-slides/slide-5.jpg');
          background-size: cover;
          background-position: center;
        }

      }

      .detail-2 {
        background-color: $green-2;
        border-left: 20px solid #cedd61;
        min-height: 70vh;


        &.open-2{
          background-image: url('../images/trainings/new-slides/slide-4.png');
          background-size: cover;
          background-position: center;
        }
      }

      .detail-3 {
        background-color: $blue-2;
        border-left: 20px solid #55b7c5;
        min-height: 70vh;

        &.open-3{
          background-image: url('../images/trainings/new-slides/slide-3.png');
          background-size: cover;
          background-position: center;
        }
      }
      
      
      .detail-4 {
        background-color: $orange-4;
        border-left: 20px solid #fe9e4a;
        min-height: 70vh;
        &.open-4{
          background-image: url('../images/trainings/new-slides/slide-2.png');
          background-size: cover;
          background-position: right;
        }
      }

      .detail-5 {
        background-color: $green-4;
        border-left: 20px solid #52bb48;
        min-height: 70vh;

        &.open-5{
          background-image: url('../images/trainings/new-slides/slide-1.png');
          background-size: cover;
          background-position: center;
        }
      }

      p {
        text-align: right;
        margin-bottom: 0;
      }

      .arrow {
        width: 25px;
        height: auto;
      }

      .smile {
        width: 220px;
        height: auto;
        margin-top: 50px;
        opacity: 0.3;
      }

      .default-img{
        height: 70vh;
      }

     
      @media only screen and (min-width: 568px) {
        .heading {
          font-size: 18px;
        }
        .arrow {
          margin-left: 20px;
        }
      }
      @media only screen and (min-width: 768px) {
        .heading {
          align-items: end;
          font-size: 20px;
          padding: 10px;
        }
        .arrow {
          align-self: end;
          margin-left: 40px;
        }
        .detail-1 {
          border-left: 50px solid #f5c368;
        }

        .detail-2 {
          border-left: 50px solid #cedd61;
        }

        .detail-3 {
          border-left: 50px solid #55b7c5;
        }

        .detail-4 {
          border-left: 50px solid #fe9e4a;
        }

        .detail-5 {
          border-left: 50px solid #52bb48;
        }

      }
      @media only screen and (min-width: 992px) {
        .heading {
          font-size: 22px;

          padding: 20px;
        }
        .arrow {
          width: 35px;
          height: auto;
          margin-left: 30px;
        }
      }
      @media only screen and (min-width: 1100px) {
        .heading {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          min-height: 160px;
          font-size: 26px;
        }
        .heading p {
          align-self: center;
        }
        .arrow {
          align-self: end;
          width: 45px;
          height: auto;
          margin-bottom: 25px;
        }
      }
    }

    .orange-1 {
      background-color: $orange-1;
    }
    .orange-2 {
      background-color: $orange-2;
    }
    .orange-3 {
      background-color: $orange-3;
    }
    .orange-4 {
      background-color: $orange-4;
    }

    .green-1 {
      background-color: $green-1;
    }
    .green-2 {
      background-color: $green-2;
    }
    .green-3 {
      background-color: $green-3;
    }
    .green-4 {
      background-color: $green-4;
    }

    .blue-1 {
      background-color: $blue-1;
    }

    .blue-2 {
      background-color: $blue-2;
    }
  }
}


