.footer{
    
    background-color: #80868C;
    padding-top: 50px;
    padding-bottom: 50px;

    .vision-2030-logo{
        padding-left: 30px;
        width: 150px;
        border-left: 2px solid white;
    }
    
    .gea-logo{
        margin-left: 10px;
        width: 170px;
    }
    
    .barnamg-logo{
        padding-right: 30px;
        width: 220px;
    }

    .combined-logos{
        width: 300px;
    }

    .social-and-contacts{
        margin-bottom: 100px;
    }

    .social-icons{
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 10px
    }

    .contact-section{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        span{
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .copyright{
        color: #E5E5E5;
        font-size: 1.2rem !important;
        font-weight: normal;
    }


    h5{
        font-size: 16px;
    }

    @media (max-width: 1300px) {
        
      

        .combined-logos{
            width: 250px;
        }
        
        .social-icons{
            justify-content: center;
            margin-bottom: 20px;
        }

        .contact-section{
            flex-direction: row;
            justify-content: center;
        }

    }

    @media (max-width: 768px) {
        .vision-2030-logo{
            width: 140px;
        }
    
        .gea-logo{
            margin-left: 0px;

        }

        .barnamg-logo{
            padding-right: 10px;
            width: 190px;
        }

        .social-icons{
            justify-content: flex-end;
            margin-bottom: 40px;
        }

        .contact-section{
            flex-direction: column;
            justify-content: center;
            span{
                justify-content: center;
            }
        }


    .copyright{
        font-size: 1.2rem !important;
    }
       
    }
    
    @media (max-width: 576px) {
        .vision-2030-logo{
            padding-left: 20px;
            width: 140px;
        }
        
        .gea-logo{
            margin-left: 0px;
            width: 120px;
            padding-right: 0px;
        }

        .social-and-contacts{
            margin-bottom: 60px;
        }

        .contact-section{
            p{
                font-size: 1rem !important;
                &:first-child{
                    margin-bottom: 10px !important;
                }
            }
        }

        .copyright{
            font-size: 1rem !important;
        }
        
    }
    
    @media (max-width: 480px) {
        .vision-2030-logo{
            padding-left: 20px;
            width: 100px;
            border-left: 0px;
        }

    }
}