$primary: #8e46cd;
$secondary: #00aa2b;
$light-gray: #8b8491;
$gray: #707070;
$light-purple: #a671d4;

$orange-1: #F5C46B;  
$orange-2: #F4B73F;  
$orange-3: #FBA049;  
$orange-4: #FF8900;  

$green-1: #CEDE62;  
$green-2: #C5D82E;  
$green-3: #52BB48;  
$green-4: #00AD00;  

$blue-1: #45B7C5;  
$blue-2: #17A7B9;  
