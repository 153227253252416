.previous-work{
    img{
        width: 100%;
    }
    .img-wrapper{
        position: relative;
    }

     .date {
      position: absolute;
      top: 15px;
      right: 25px;
      background: #F38903;
      padding: 5px 20px;
      color: white;
      font-size: 14px;
    }
    
    .title {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      background: #80868C66;
      padding: 5px 20px;
      color: white;
      font-size: 32px;
    }
}