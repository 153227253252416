.objectives{
    position: relative;
    text-align: right;
    margin-bottom: 100px;
    margin-top: -100px;

    .side-half-smiles{
        width: 8%;
    }

    .heading{
        text-align: center;
        margin-top: -50px;
        margin-bottom: 50px;
    }

    .objective{
        background-color: #F5F5F5;
        text-align: center;
        height: 100%;
        img{
            width: 40%;
        }
        h5{
            padding: 0px 50px 10px;
            line-height: 35px;
            color: black;
            font-weight: 700;
            font-size: 1.0rem;
        }
    }
    @media (max-width: 1300px) {
        margin-top: -50px
    }

    @media (max-width: 992px) {
        margin-top: 0px
    }

    @media (max-width: 768px) {
            .objective{
                margin-bottom: 10px;
            }
            .side-half-smiles{
                width: 15%;
            }
    }
}