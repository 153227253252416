.hero-section {
  height: 100vh;
  position: relative;

  .landing-area {
    height: 85vh;
    background-color: #ff8900;

    .img-container {
      background-image: url("../images/landing/landing-img.jpg");
      height: 85vh;
      background-size: cover;
      background-position: right;
    }

    .logo-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      padding-top: 50px;

      .landing-logo {
        width: 60%;
        margin-right: 50px;
      }
    }
  }

  .landing-bottom {
    height: 15vh;
    background-color: #f4b73f;
    z-index: 100;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 50px;
    img {
      height: 80%;
    }
  }

  .center-smile {
    position: absolute;
    width: 20%;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 1200px) {

    .landing-bottom {
      img {
        height: 50%;
      }
    }
  }


  @media (max-width: 768px) {
    .landing-area {
      .img-container {
        background-image: url("../images/landing/landing-img-mob.jpg");
      }

      .logo-container {
        padding-bottom: 100px;
        .landing-logo {
          width: 90%;
          margin-right: 0px;
        }
      }
    }

    .landing-bottom {
      padding-left: 10px;
      img {
        height: 50%;
      }
    }
  }
}
